@font-face {
  font-family: 'Contract';
  src: url('./Contract-Regular.ttf') format('truetype');
}

:root {
  --gradient-color: rgb(255, 0, 0), rgba(255, 0, 0, 0) !important;
}

body {
  background: #111;
  overflow: hidden;
  overflow-x: hidden;
}

.App {
  margin: 20px;
  border-radius: 32px;
  height: calc(100vh - 36px) !important;
  /* position: absolute; */
  overflow: hidden;
  cursor: crosshair;
  transform: translateZ(0);
  /* transform: rotate(90deg) */
}

@media only screen and (max-width: 900px) {
  .App {
    margin: 10px;
  }
}

.MQcont {
  font-family: 'Contract';
  color: #CEE0D9;
}

/* .MQcont {
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin { 
  0% { 
    transform: rotate(1, 1, 1, 0deg);
  } 25% { 
    transform: rotate(1, 1, 1, 90deg);
  }
   50% { 
    transform: rotate(1, 1, 1, 90deg);
  } 
  75% { 
    transform: rotate(1, 1, 1, 0deg);
  }
  100% { 
      transform: rotate(1, 1, 1, 0deg);
  }
} */

.toplogo {
  width: 80px;
}

.logo {
  display: none;
  width: 64px;
  position: absolute;
  left: calc(50% - 32px);
  top: calc(50% - 35px);
}

.block1 {
  width: 200px;
  height: 50px;
  box-shadow: inset 20px 0px 40px #eee, inset 0px -24px 25px rgba(206, 224, 217, 0.5);
  border-radius: 100px;
}

.block2 {
  width: 200px;
  height: 50px;
  box-shadow: inset 20px 0px 40px #eee, inset 0px -24px 25px rgba(206, 224, 217, 0.5);
  border-radius: 100px;
}
.block2 {
  width: 200px;
  height: 50px;
  box-shadow: inset -20px 0px 40px #eee, inset 0px -24px 25px rgba(206, 224, 217, 0.5);
  border-radius: 100px;
}
.block3 {
  width: 200px;
  height: 50px;
  box-shadow: inset -20px 20px 40px #eee, inset 0px -10px 25px rgba(206, 224, 217, 0.5);
  border-radius: 100px;
}
.block4 {
  width: 200px;
  height: 50px;
  box-shadow: inset 0px 20px 40px #eee, inset 20px -10px 25px rgba(206, 224, 217, 0.5);
  border-radius: 100px;
}

.overlay {
  --gradient-color: rgb(0, 174, 255), rgba(255, 0, 0, 0) !important;
}

.marquee {
  --duration: 20s !important;
}

/* .marquee:hover {
  opacity: 0.5;
} */

.mainText {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
}

.bg {
  z-index: -10;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.mainText img {
  margin-top: 8px;
}